
import { dispatchGetNewsArticle } from '@/store/news/actions';
import { readNewsArticle } from '@/store/news/getters';
import { Component, Vue, Watch } from 'vue-property-decorator';
import {LMap, LTileLayer} from 'vue2-leaflet';

@Component({
    components: {
        LMap,
    LTileLayer,
    },
})

export default class News extends Vue {
  public loadingState: any = {
    page: true,
  };

  @Watch('$route.params')
  public onParamsChanged(toParams, previousParams) {
    console.log('params changed', toParams, previousParams);
    this.loadPageData();
  }

  public mounted() {
    this.loadPageData();
  }

  public async loadPageData() {
    this.loadingState.page = true;
    await dispatchGetNewsArticle(
      this.$store, {
        article_id: Number(this.$router.currentRoute.params.id),
      },
    );
    if (!this.newsDetail) {
      this.$router.push('/home');
      return;
    }
    this.loadingState.page = false;
  }

  get newsDetail() {
    return readNewsArticle(this.$store);
  }
}
